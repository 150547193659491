const hasAccountsOrFake = (application) => {
  let hasCarmaAccounts = true;
  let hasFakeOrNoMoreBusiness = false;

  const accountsCreated = application && application.accountsCreated ? application.accountsCreated : null;
  if (accountsCreated && accountsCreated.length > 0) {
    const failedCarmaAccounts = accountsCreated.some(e => ['FAILED_FUNDING'].includes(e));
    const bypassedCarmaAccounts = accountsCreated.some(e => ['BYPASS_FUNDING'].includes(e));
    hasFakeOrNoMoreBusiness = accountsCreated.some(e => ['FAKE', 'NMB'].includes(e));
    hasCarmaAccounts = !failedCarmaAccounts && !bypassedCarmaAccounts && !hasFakeOrNoMoreBusiness;
  } else {
    hasCarmaAccounts = false;
  }

  return { hasCarmaAccounts, hasFakeOrNoMoreBusiness };
};

export default hasAccountsOrFake;
