import moment from 'moment';
import { formValueSelector } from 'redux-form';
import { findContactById, findContactByType, findCountry, isTselCountry, mapContact }
  from '../../../containers/contactSearchAndFormat';
import { mapRegionOptions } from '../../../lib/sortFilterMapCountriesRegions';
import { validateExpirationDate } from './validateIdentity';
import * as identificationTypes from '../../../components/PersonalInfo/Identity/identificationTypes';

const monthOptions = [
  { value: '', text: '' },
  { value: '1', text: 'January' },
  { value: '2', text: 'February' },
  { value: '3', text: 'March' },
  { value: '4', text: 'April' },
  { value: '5', text: 'May' },
  { value: '6', text: 'June' },
  { value: '7', text: 'July' },
  { value: '8', text: 'August' },
  { value: '9', text: 'September' },
  { value: '10', text: 'October' },
  { value: '11', text: 'November' },
  { value: '12', text: 'December' },
];

const mapDayOptions = () => {
  const options = [{ value: '', text: '' }];

  [...Array(31)].map((_, d) => {
    const day = d + 1;
    options.push({ value: (day).toString(), text: (day).toString() });
    return true;
  });

  return options;
};

const mapBirthYearOptions = () => {
  const options = [{ value: '', text: '' }];

  const max = new Date().getFullYear() - 21;

  [...Array(90)].map((_, y) => {
    options.push({ value: (max - y).toString(), text: (max - y).toString() });
    return true;
  });

  return options;
};

const mapExpirationYearOptions = () => {
  const options = [{ value: '', text: '' }];

  const min = new Date().getFullYear();

  [...Array(31)].map((_, y) => {
    options.push({ value: (min + y).toString(), text: (min + y).toString() });
    return true;
  });

  return options;
};

const deconstructDate = (input) => {
  const defaultDate = {
    month: '',
    day: '',
    year: '',
  };
  const inputDate = moment(input);
  if (inputDate.isValid()) {
    const [date] = input.split('T');
    const [year, month, day] = date.split('-');
    // removing 0 prefix: 04 => 4
    defaultDate.month = month.replace(/^0+/, '');
    defaultDate.day = day.replace(/^0+/, '');
    defaultDate.year = year;
  }
  return defaultDate;
};

export const mapIdentificationTypes = (countryOfResidence, countries) => {
  let options = [
    { value: '', text: '' },
    { value: identificationTypes.DRIVERS_LICENSE, text: 'Driver\'s License' },
    { value: identificationTypes.PASSPORT, text: 'Passport' },
    { value: identificationTypes.OTHER, text: 'Other Government-Issued ID' },
  ];

  if (countryOfResidence === undefined) {
    return options;
  }

  if (countryOfResidence !== 'US') {
    options = options.filter((el) => { return el.value !== identificationTypes.DRIVERS_LICENSE; });
  }

  if (!isTselCountry(countries, countryOfResidence)
    && countryOfResidence !== 'US'
    && countryOfResidence !== 'CH') {
    options = options.filter((el) => { return el.value !== identificationTypes.OTHER; });
  }
  return options;
};

export function mapIdentity(identity, contact, countries) {
  const birthDate = deconstructDate(identity.birthDate);
  const country = findCountry(identity.countryOfCitizenship, countries);
  const expirationDate = deconstructDate(identity.identificationExpirationDate);
  const isIdentificationNumberExpired = validateExpirationDate(
    expirationDate.year,
    expirationDate.month,
    expirationDate.day,
  );
  return {
    id: identity.id,
    sort: contact.type === 'primary' ? 0 : contact.id,
    countryOfResidence: contact.countryOfResidence,
    countryOfCitizenship: country ? country.countryCode : '',
    countryOfCitizenshipName: country ? country.country : '',
    birthMonth: birthDate.month ? birthDate.month : '',
    birthDay: birthDate.day ? birthDate.day : '',
    birthYear: birthDate.year ? birthDate.year : '',
    expirationMonth: !isIdentificationNumberExpired ? expirationDate.month : '',
    expirationDay: !isIdentificationNumberExpired ? expirationDate.day : '',
    expirationYear: !isIdentificationNumberExpired ? expirationDate.year : '',
    identificationSSN: '',
    identificationCrm: identity.identificationCrm ? identity.identificationCrm : '',
    identificationType: !isIdentificationNumberExpired ? identity.identificationType : '',
    identificationNumber: !isIdentificationNumberExpired ? identity.identificationNumber : '',
    identificationRegion: !isIdentificationNumberExpired ? identity.identificationRegion : '',
    ssnIsReadOnly: identity.ssnIsReadOnly ? identity.ssnIsReadOnly : false,
    contact: mapContact(contact, countries),
  };
}

export function newIdentity(contact, countries) {
  return {
    id: null,
    sort: contact && contact.type === 'primary' ? 0 : 1,
    countryOfResidence: contact.countryOfResidence,
    countryOfCitizenship: '',
    countryOfCitizenshipName: '',
    birthMonth: '',
    birthDay: '',
    birthYear: '',
    expirationMonth: '',
    expirationDay: '',
    expirationYear: '',
    identificationCrm: '',
    identificationSSN: '',
    identificationType: '',
    identificationNumber: '',
    identificationRegion: '',
    ssnIsReadOnly: false,
    contact: mapContact(contact, countries),
  };
}

function clearMask(isExistingCustomer, fieldValue) {
  let result = fieldValue;
  if (fieldValue) {
    if (!isExistingCustomer && fieldValue.includes('*')) {
      result = '';
    }
  }
  return result;
}

export function mapStateToInitialValues(state, isJoint) {
  const identities = [];
  const isExistingCustomer = state && state.registration && state.registration.isExistingCustomer;

  if (state && state.identity && state.identity.length > 0) {
    state.identity.forEach((identity) => {
      if (state && state.contact && state.contact.length > 0) {
        const contact = findContactById(identity.contactId, state.contact);
        if ((contact.type === 'primary') || (contact.type === 'joint' && isJoint)) {
          const identityUnmasked = Object.assign({}, identity);
          identityUnmasked.identificationSSN = clearMask(isExistingCustomer, identity.identificationSSN);
          identityUnmasked.identificationNumber = '';
          identities.push(mapIdentity(identityUnmasked, contact, state.countries));
        }
      }
    });
  }

  if (state && state.contact && state.contact.length > 0) {
    if (identities.length < 1) {
      const contact = findContactByType('primary', state.contact);
      identities.push(newIdentity(contact, state.countries));
    }

    if (identities.length < 2 && isJoint) {
      const contact = findContactByType('joint', state.contact);
      identities.push(newIdentity(contact, state.countries));
    }
  }

  identities.sort((a, b) => {
    return a.sort - b.sort;
  });

  return {
    identities,
    isExistingCustomer,
  };
}

export function props(state, isJoint) {
  const regions = [];

  const showExpirationDate = [];
  const showIdentificationSSN = [];
  const showIdentificationNumber = [];
  const showIdentificationRegion = [];
  const identificationNumberLabel = ['', ''];
  const identificationOptions = [];
  const isExistingCustomer = [false, false];
  const isFieldEnabled = [];

  const initialValues = mapStateToInitialValues(state, isJoint);
  isExistingCustomer[0] = initialValues.isExistingCustomer;

  const identityExists = (state.identity && state.identity.length > 0);

  const selector = formValueSelector('personal-info');
  const identities = selector(state, 'identities');

  if (identities && identities.length > 0) {
    for (let i = 0; i < identities.length; i += i + 1) {
      regions[i] = mapRegionOptions(state.countries, 'US', true);
      const countryOfResidence = identities[i].countryOfResidence;
      const countryOfCitizenship = identities[i].countryOfCitizenship;
      showIdentificationSSN[i] = countryOfResidence === 'US' || countryOfCitizenship === 'US';
      identificationOptions[i] = mapIdentificationTypes(identities[i].countryOfResidence, state.countries);
      isFieldEnabled.push(Object.assign({}, {
        countryOfResidence: !isExistingCustomer[i] || initialValues.identities[i].countryOfCitizenship === '',
        birthDay: !isExistingCustomer[i] || initialValues.identities[i].birthDay === '',
        birthMonth: !isExistingCustomer[i] || initialValues.identities[i].birthMonth === '',
        birthYear: !isExistingCustomer[i] || initialValues.identities[i].birthYear === '',
        identificationSSN: showIdentificationSSN[i] && !initialValues.identities[i].ssnIsReadOnly,
      }));
      const identificationType = identities[i].identificationType;
      if (identities[i].identificationType[i] !== '') {
        showIdentificationNumber[i] = true;
        showExpirationDate[i] = true;
        switch (identificationType) {
          case identificationTypes.DRIVERS_LICENSE:
            showIdentificationRegion[i] = true;
            identificationNumberLabel[i] = 'Driver\'s License Number';
            break;
          case identificationTypes.PASSPORT:
            showIdentificationRegion[i] = false;
            identificationNumberLabel[i] = 'Passport Number';
            break;
          case identificationTypes.OTHER:
            showIdentificationRegion[i] = false;
            identificationNumberLabel[i] = 'Other Government Identification Number';
            break;
          default:
            showIdentificationNumber[i] = false;
            showIdentificationRegion[i] = false;
            showExpirationDate[i] = false;
            identificationNumberLabel[i] = 'Identification Number';
            break;
        }
      }
    }
  }

  return {
    identityExists: !!identityExists,
    identities: identities || [],
    initialValues,
    countries: state.countries,
    regions,
    months: monthOptions,
    days: mapDayOptions(),
    yearsBirthdate: mapBirthYearOptions(),
    yearsExpirationDate: mapExpirationYearOptions(),
    showExpirationDate,
    showIdentificationSSN,
    showIdentificationNumber,
    showIdentificationRegion,
    identificationNumberLabel,
    identificationOptions,
    isExistingCustomer,
    isFieldEnabled,
  };
}
